import $ from "jquery";

$(document).ready(function () {
  setTimeout(function () {
    if (
      window.location.pathname == "/" ||
      window.location.pathname == "/index.php"
    ) {
      window.location.replace("/home");
    }
  }, 2000);

  // Open mainnav with click or Enter press
  $("#DriveTVhead").on("click", function (e) {
    $("#mainnav").toggleClass("open");
  });
  $("#DriveTVhead").on("keydown", function (e) {
    if (e.keyCode === 13) {
      $(this).click();
    }
  });
  $("#icon-white").on("click", function (e) {
    $("#mainnav").toggleClass("open");
  });
  $("#icon-white").on("keydown", function (e) {
    if (e.keyCode === 13) {
      $(this).click();
    }
  });

  // Drivers Bio
  $(".driver").on("click", function (e) {
    $(this).next().toggleClass("open");
  });
  $(".driver").on("keydown", function (e) {
    if (e.keyCode === 13) {
      $(this).click();
    }
  });

  //Job description
  $(".job").on("click", function (e) {
    $(this).next().toggleClass("open");
  });
  $(".job").on("keydown", function (e) {
    if (e.keyCode === 13) {
      $(this).click();
    }
  });

  $(".close").on("click", function (e) {
    $(this).parent().parent().removeClass("open");
  });

  $(".modal").on("click", function (e) {
    $(this).removeClass("open");
  });

  $(".spotlight").slick({
    infinite: true,
    nextArrow:
      "<button type='button' aria-label='next-slide' class='slick-next'></button>",
    prevArrow:
      "<button type='button' aria-label='previous-slide' class='slick-prev'></button>",
  });

  $(".slider").slick({
    infinite: true,
    nextArrow:
      "<button type='button' aria-label='next' class='slick-next'></button>",
    prevArrow:
      "<button type='button' aria-label='previous' class='slick-prev'></button>",
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".gps").slick({
    // centerMode: true,
    centerPadding: "50px",
    nextArrow:
      "<button type='button' aria-label='next' class='slick-next'></button>",
    prevArrow:
      "<button type='button' aria-label='previous' class='slick-prev'></button>",
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $(".campaigns").slick({
    centerMode: true,
    centerPadding: "60px",
    nextArrow:
      "<button type='button' aria-label='next' class='slick-next'></button>",
    prevArrow:
      "<button type='button' aria-label='previous' class='slick-prev'></button>",
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          centerMode: true,
          centerPadding: "50px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
    ],
  });
});

//Implementing navigation of slides using mouse scroll
// $('.spotlight').on('wheel', (function(e) {
//   e.preventDefault();
//   if (e.originalEvent.deltaY < 0) {
//     $(this).slick('slickPrev');
//   } else {
//     $(this).slick('slickNext');
//   }
// }));

//
// $('.spotlight').on('afterChange', function(event, slick, currentSlide) {
//   // console.log(slick, currentSlide);
//   if (slick.$slides.length - 1 == currentSlide) {
//     $('html, body').animate({
//       scrollTop: ($('.spotlight').offset().top + $('.spotlight').height()),
//       scrollLeft: 0
//     }, 1000);
//   }
//   if (currentSlide == 0) {
//     $('html, body').animate({
//       scrollTop: ($('.spotlight').offset().top - $(window).height()),
//       scrollLeft: 0
//     }, 1000);
//   }
// });
