import $ from "jquery";

//Work images
if (document.body.classList.contains("campaign")) {
  $(window).scroll(function () {
    $(".images").each(function () {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $(this).offset().top;
      var elemBottom = elemTop + $(this).height();

      if (elemTop <= docViewBottom && elemBottom >= docViewTop) {
        // var y = (docViewTop - elemTop);
        // var z = (docViewBottom - elemTop) / (elemBottom - docViewTop);
        var max = $(this).height() + docViewBottom;
        // var e = (elemTop + docViewTop);
        var a = scaleBetween(1 / elemBottom, 1, 1.2, 1 / max, 1 / docViewTop);
        $(this)
          .children(".image")
          .css({
            transform: "scale(" + a + ")",
          });
      }
    });
  });
}

function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (
    ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed
  );
}
