import $ from "jquery";

$(".filter a").on("click", function (e) {
  e.preventDefault();

  if (this.className === "active") {
    $(".gps").slick("slickUnfilter");
    $(this).removeClass("active");
  } else {
    var topic = $(this).text();
    $(".gps").slick("slickUnfilter");
    $(".gps").slick("slickFilter", '[data-input*="' + topic + '"]');
    $("a.active").removeClass("active");
    $(this).addClass("active");
  }
});
