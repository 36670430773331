import ScrollMagic from "scrollmagic";

// scrollmagic.io/docs
//
// Only run on homepage
if (document.body.classList.contains("home")) {
  // init controller
  var controller = new ScrollMagic.Controller();

  // create a scene
  new ScrollMagic.Scene({
    triggerElement: ".offset-border",
    triggerHook: 0.5, // show, when scrolled 50% into view
    duration: "80%", // hide before exiting view
    offset: 50, // move trigger to center of element
  })
    .setClassToggle("#img-overlay", "flyover") // add class to reveal
    .addTo(controller);
}
